::-webkit-scrollbar {
    width: 6px;  /* Width of the vertical scrollbar */
    height: 6px; /* Height of the horizontal scrollbar */
}

/* Styling for the scrollbar thumb (the part you drag) */
::-webkit-scrollbar-thumb {
    background-color: #a0a0a0; /* Thumb color */
    border-radius: 4px;        /* Rounded corners for the thumb */
}

/* Styling for the scrollbar track (the part the thumb slides within) */
::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Track color */
    border-radius: 4px;        /* Rounded corners for the track */
}